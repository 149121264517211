.child {
  :global {
    td.ant-table-cell:nth-child(1) {
      margin-left: 1rem;
      padding-left: 1rem !important;
    }
  }
  &_expanded {
    background-color: #fff0b3;
  }
}

