@import '@/styles/variables';

.red {
  background-color: @color-red60;
}

.green {
  background-color: @color-green60;
}

