@import './normalize.css';

.discountTable__summerRow {
  background-color: #fff1cc87;
}

.discountTable__winterRow {
  background-color: #8cc7ff7b;
}

.discountTable__allYearRow {
  background-color: #cc80ff90;
}

iframe {
  display: none;
}

.cut-text {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 160px;
  height: 1.2em;
  white-space: nowrap;
}

.dots {
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.ant-btn.ant-btn-sm {
  height: 20px;
  line-height: 1;
}

