.parent {
  background-color: #ffbb0020;
}
.noweopony {
  background-color: #c8e4ff;
}
.noweopony_channel_variant {
  background-color: #f0c2f4;
}

