@import '@/styles/variables';

.calculator {
  display: flex;
  background-color: @color-green60;
  height: 100%;
  align-items: center;
  > div {
    margin-left: 8px;
  }
}
.calculatorError {
  background-color: @color-red60;
}

.white {
  color: #fff;
}

.summary {
  display: flex;
  flex-direction: column;
}

