.item {
  padding: 10px 0;
}
.header {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  &__name {
    flex: 1;
  }
  > * {
    margin-right: 5px;
  }
}
.collapse {
  &_open {
    transform: rotate(90deg);
  }
}

.linePriceCorrect {
  background-color: #86b30030;
}
.linePriceInvalid {
  background-color: #ff1a1a30;
}

