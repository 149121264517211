.copy {
  position: relative;
  color: inherit;
  :global {
    .ant-typography {
      display: inline-block;
      width: 100%;
      color: inherit;
    }
    .ant-typography-copy {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      opacity: 0; //Hide default AntD copy icon
    }
  }
}

