.profitLevel {
  &_1 {
    background-color: #ff9f80;
  }
  &_2 {
    background-color: #ffbf80;
  }
  &_3 {
    background-color: #bfff80;
  }
  &_4 {
    background-color: #80ff80;
  }
}

