.layout {
  .headerLeft {
    display: flex;
    height: 100%;
  }
  .hamburger {
    width: 40px;
    justify-content: center;
    align-items: center;
    display: flex;
    height: 100%;
    background-color: transparent;
    border-width: 0;
    cursor: pointer;
  }
  :global {
    .ant-layout {
      min-height: 100vh;
    }
    .ant-layout-header {
      justify-content: space-between;
      align-items: center;
      display: flex;
      height: 45px;
      padding: 0;
      padding-right: 24px;
      line-height: normal;
    }
  }
}

