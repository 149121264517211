.wrapper {
  display: flex;
  input {
    border: 1px solid grey;
    max-width: 80px;
    color: black;
    padding: 0 2px;
  }
}

