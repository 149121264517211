.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 10px;
}

.header__title {
  margin: 0;
}
.header__search {
  flex: 1;
  margin-left: 20px;
}

.header__select {
  margin-left: 20px;
}

.header__selectContent {
  min-width: 200px;
}

.availabilityLevel_1 {
  background-color: #ff9999;
}

.availabilityLevel_2 {
  background-color: #fff0b3;
}

