.table {
  :global {
    .ant-table table {
      border-collapse: collapse;
    }

    tr.drop-over-downward td {
      border-bottom: 2px dashed #1890ff;
    }

    tr.drop-over-upward td {
      border-top: 2px dashed #1890ff;
    }

    .ant-table.ant-table-small .ant-table-cell {
      padding: 2px;
    }

    .ant-table.ant-table-small .ant-table-tbody > tr:hover > td {
      background: #cbcbcb;
    }
  }

  &_small {
    :global {
      .ant-table {
        line-height: 1.1;
      }
      .ant-table.ant-table-small .ant-table-cell {
        padding: 0px;
        font-size: 12px;
        min-width: 14px;
      }
    }
  }
}

